import { Component, OnInit , ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DataService } from '../../services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-capturecard',
  templateUrl: './capturecard.component.html',
  styleUrls: ['./capturecard.component.css']
})
export class CapturecardComponent implements OnInit {
  @ViewChild('submitting') submitting;
  personalForm: FormGroup;
  businessForm: FormGroup;
  addressForm: FormGroup;
  shippingForm: FormGroup;
  uploadForm: FormGroup;
  lastForm: FormGroup;
  _submitting:string;
  business:any;
  order:any;
  _business:any;
  _order:any;
  _services:any;
  businessid:any;
  error:any;
  userid:any;
  loggedIn:boolean;
  partnerid:any;
  faSpinner = faSpinner;
  faMinusCircle = faMinusCircle;
  _logo=false;
  _avatar=false;
  _image1=false;
  _image2=false;
  _image3=false;
  _image4=false;
  logob64:any;
  avatarb64:any;
  image1b64:any;
  image2b64:any;
  image3b64:any;
  image4b64:any;
  fb="";
  twitter="";
  insta="";
  accountid:any;
  services=[];
  theme:string;

  provinces: P[] = [
    {value: '0', viewValue: 'KwaZulu Natal'},
    {value: '1', viewValue: 'Eastern Cape'},
    {value: '2', viewValue: 'Mpumalanga'},
    {value: '3', viewValue: 'Gauteng'},
    {value: '4', viewValue: 'Limpopo'},
    {value: '5', viewValue: 'North West'},
    {value: '6', viewValue: 'Free State'},
    {value: '7', viewValue: 'Northern Cape'},
    {value: '8', viewValue: 'Western Cape'},
   
  ];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private dataService:DataService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this.logob64="";
    this.avatarb64="";
    this.image1b64="";
    this.image2b64="";
    this.image3b64="";
    this.image4b64="";
    this.theme='#FFA500';
    this.authService.authStatus.subscribe(value => this.loggedIn = value);
   
    //Perform the below to retrieve user avatar / initials when the user is logged on and the navbar is loading
    //The observable values, would have not been set from anywhere else
    if (this.loggedIn){
      this.userid=localStorage.getItem('id');
            }else{
        this.userid="-99";
      }
      console.log('USERID'+this.userid);
    this.createForms();
    
  }

  createForms(){
    this.personalForm = this.formBuilder.group({
      contactname: [,Validators.required],
      surname: [,Validators.required],
      contactbrief: [],
      accountid: [],
      hbgcolor: [],
      tel: [,Validators.required],
      email: [,[Validators.required,]],
      //email: [,[Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    this.businessForm = this.formBuilder.group({
      name: [],
      contactjobtitle: [],
      businessbrief: [],
      services: [],
      website: [,[Validators.pattern('^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?')]],
    });
    this.addressForm = this.formBuilder.group({
      rstreet: [,Validators.required],
      rsuburb: [,Validators.required],
      rtown: [,Validators.required],
      rprov: [,Validators.required],
      rcode: [,Validators.required],
    });
    this.shippingForm = this.formBuilder.group({
      sstreet: [,Validators.required],
      ssuburb: [,Validators.required],
      stown: [,Validators.required],
      sprov: [,Validators.required],
      scode: [,Validators.required],
    });
    this.uploadForm = this.formBuilder.group({
      avatar: [],
      logo: [],
      image1: [],
      image2: [],
      image3: [],
      image4: [],
    });
    this.lastForm = this.formBuilder.group({
      message: [],
      fbpage: [],
      twitterpage: [],
      instapage: [],
    });
  }

  remove(s){
    console.log('S='+s);
    this.services=this.filterArray(s, this.services);
  }

  addService(){
    console.log('S='+this.businessForm.controls.services.value)
    this.services.push({'name':this.businessForm.controls.services.value})
  }

  doAccountid(){
    if(this.personalForm.controls.contactname.value && this.personalForm.controls.surname.value){
      this.accountid=this.personalForm.controls.contactname.value+this.personalForm.controls.surname.value
      this.accountid=this.accountid.replace(/ /g, '');
    }
  }

  addressnext(){
    this.shippingForm.patchValue({sstreet: this.addressForm.controls.rstreet.value});
    this.shippingForm.patchValue({ssuburb: this.addressForm.controls.rsuburb.value});
    this.shippingForm.patchValue({stown: this.addressForm.controls.rtown.value});
    this.shippingForm.patchValue({scode: this.addressForm.controls.rcode.value});
    this.shippingForm.patchValue({sprov: this.addressForm.controls.rprov.value});
    this.next();
  }

  next(){
    console.log('THEME='+this.theme)
    let name = this.businessForm.controls.name.value;
    let website = this.businessForm.controls.website.value;
    let email = this.personalForm.controls.email.value;
    let tel = this.personalForm.controls.tel.value;
    let fax = "";
    let rstreet = this.addressForm.controls.rstreet.value;
    let rsuburb = this.addressForm.controls.rsuburb.value;
    let rtown = this.addressForm.controls.rtown.value;
    let rcode = this.addressForm.controls.rcode.value;
    let rprov = this.addressForm.controls.rprov.value;
    let sstreet = this.shippingForm.controls.sstreet.value;
    let ssuburb = this.shippingForm.controls.ssuburb.value;
    let stown = this.shippingForm.controls.stown.value;
    let scode = this.shippingForm.controls.scode.value;
    let sprov = this.shippingForm.controls.sprov.value;
    let pstreet = "";
    let psuburb = "";
    let ptown = "";
    let pcode = "";

    let location = "";
    let stage = "";
    let industry = "";
    let subindustry = "";
    let tradinglocation = "";
    let turnover = "";
    let service = "";
    let vision = "";
    let fiveyears = "";
    let clientelle = "";


    let regno = "";
    let taxno = "";
    let sarspin = "";
    let vat = "";
    let bbbee = "";
    let tourism = "";
    let cidb = "";
    let statindustry = "";
    let coida = "";
    let sabs = "";
    let iso = "";
    let health = "";
    let env = "";
    let planning = "";
    let tourismaffiliation = "";
    let businesspermit = "";

    let avatar = this.uploadForm.controls.avatar.value;
    let logo = this.uploadForm.controls.logo.value;
    let image1 = this.uploadForm.controls.image1.value;
    let image2 = this.uploadForm.controls.image2.value;
    let image3 = this.uploadForm.controls.image3.value;
    let image4 = this.uploadForm.controls.image4.value;

    let message = this.lastForm.controls.message.value;
    let fbpage = this.lastForm.controls.fbpage.value;
    let twitterpage = this.lastForm.controls.fbpage.value;
    let instapage = this.lastForm.controls.fbpage.value;

    let contactname = this.personalForm.controls.contactname.value;
    let surname = this.personalForm.controls.surname.value;
    let contactjobtitle = this.businessForm.controls.contactjobtitle.value;
    let businessbrief = this.businessForm.controls.businessbrief.value;
    let contactjbrief = this.personalForm.controls.contactbrief.value;
    let idno = "";
    let contactnumber = this.personalForm.controls.tel.value;
    let contactemail = this.personalForm.controls.email.value;
    let accountid = this.accountid;
    let hbgcolor = this.theme;
    let race = "";
    let education = "";

    let sharebm = "";
    let sharebf = "";
    let sharewm = "";
    let sharewf = "";
    let seniorbm = "";
    let seniorbf = "";
    let seniorwm = "";
    let seniorwf = "";
    let staffbm = "";
    let staffbf = "";
    let staffwm = "";
    let staffwf = "";
    let tempbm = "";
    let tempbf = "";
    let tempwm = "";
    let tempwf = "";

    let fin = "";
    let sales = "";
    let business = "";
    let hr = "";
    let strat = "";
    let tax = "";
    let marketing = "";
    let presenting = "";
    let tendering = "";
    
    

    this.business = {name:name, website:website, email:email, tel:tel, fax:fax, rstreet:rstreet, rsuburb:rsuburb, rtown:rtown, rcode:rcode, rprov:rprov, 
      pstreet:pstreet, psuburb:psuburb, ptown:ptown,pcode:pcode,description:'',
      avatar:this.avatarb64, logo:this.logob64,contactjobtitle:contactjobtitle,contactbrief:contactjbrief,businessbrief:businessbrief,
      image1:this.image1b64, image2:this.image2b64,image3:this.image3b64, image4:this.image4b64,
      location:location, stage:stage, industry:industry, subindustry:subindustry, tradinglocation:tradinglocation, turnover:turnover, service:service, vision:vision, 
      fiveyears:fiveyears, clientelle:clientelle,fbpage:fbpage,twitterpage:twitterpage,instapage:instapage,accountid:accountid,hbgcolor:hbgcolor,

    contactname:contactname, surname:surname, idno:idno, contactnumber:contactnumber, contactemail:contactemail, race:race, education:education,
    
    regno:regno, taxno:taxno, sarspin:sarspin, vat:vat, bbbee:bbbee, tourism:tourism, cidb:cidb, statindustry:statindustry, coida:coida, sabs:sabs,
    iso:iso, health:health, env:env, planning:planning, tourismaffiliation:tourismaffiliation, businesspermit:businesspermit,


    sharebm:sharebm, sharebf:sharebf, sharewm:sharewm, sharewf:sharewf, seniorbm:seniorbm, seniorbf:seniorbf, seniorwm:seniorwm,
    seniorwf:seniorwf, staffbm:staffbm, staffbf:staffbf, staffwm:staffwm, staffwf:staffwf, tempbm:tempbm, tempbf:tempbf, tempwm:tempwm, tempwf:tempwf,

    fin:fin, sales:sales, business:business, hr:hr, strat:strat, tax:tax, marketing:marketing,
    presenting:presenting, tendering:tendering,
  }

  this.order = {street:sstreet, suburb:ssuburb, town:stown, code:scode, province:sprov, message:message}
  }

  submit(){
    console.log('Submitting');
    this._submitting="SUBMITTING CARD INFO";
    this.business.fbpage=this.lastForm.controls.fbpage.value;
    this.business.twitterpage=this.lastForm.controls.twitterpage.value;
    this.business.instapage=this.lastForm.controls.instapage.value;
    console.log('FB='+this.business.fbpage);
    this.openModal(this.submitting);
    var b = {"name":this.business.name, "website":this.business.website, "email":this.business.email, "tel":this.business.tel, "fax":this.business.fax, "rstreet":this.business.rstreet, "rsuburb":this.business.rsuburb, "rtown":this.business.rtown, "rcode":this.business.rcode, "rprovince":this.business.rprov,
      "pstreet":this.business.pstreet, "psuburb":this.business.psuburb, "ptown":this.business.ptown, "pcode":this.business.pcode,
     "description":'', "logo":this.logob64,"avatar":this.avatarb64,"contactjobtitle":this.business.contactjobtitle,"contactbrief":this.business.contactbrief,"businessbrief":this.business.businessbrief,
      "image1":this.image1b64,"image2":this.image2b64, "image3":this.image3b64,"image4":this.image4b64,"accountid":this.business.accountid,"hbgcolor":this.business.hbgcolor,
      "location":this.business.location, "stage":this.business.stage, "industry":this.business.industry, "subindustry":this.business.subindustry, "tradinglocation":this.business.tradinglocation, "turnover":this.business.turnover, "service":this.business.service, "vision":this.business.vision, 
      "fiveyears":this.business.fiveyears, "clientelle":this.business.clientelle,"fbpage":this.business.fbpage,"twitterpage":this.business.twitterpage,"instapage":this.business.instapage,
  
    "contactname":this.business.contactname, "surname":this.business.surname, "idno":this.business.idno, "contactnumber":this.business.contactnumber, "contactemail":this.business.contactemail, "race":this.business.race, "education":this.business.education,
    
    "regno":this.business.regno, "taxno":this.business.taxno, "sarspin":this.business.sarspin, "vat":this.business.vat, "bbbee":this.business.bbbee, "tourism":this.business.tourism, "cidb":this.business.cidb, "statindustry":this.business.statindustry, "coida":this.business.coida, "sabs":this.business.sabs,
    "iso":this.business.iso, "health":this.business.health, "env":this.business.env, "planning":this.business.planning, "tourismaffiliation":this.business.tourismaffiliation, "businesspermit":this.business.businesspermit,
  
  
    "sharebm":this.business.sharebm, "sharebf":this.business.sharebf, "sharewm":this.business.sharewm, "sharewf":this.business.sharewf, seniorbm:this.business.seniorbm, seniorbf:this.business.seniorbf, seniorwm:this.business.seniorwm,
    "seniorwf":this.business.seniorwf, "staffbm":this.business.staffbm, "staffbf":this.business.staffbf, "staffwm":this.business.staffwm, staffwf:this.business.staffwf, tempbm:this.business.tempbm, tempbf:this.business.tempbf, tempwm:this.business.tempwm, tempwf:this.business.tempwf,
  
    "fin":this.business.fin, "sales":this.business.sales, "business":this.business.business, "hr":this.business.hr, "strat":this.business.strat, "tax":this.business.tax, "marketing":this.business.marketing,
    "presenting":this.business.presenting, "tendering":this.business.tendering, "userid":this.userid, "institutionid":this.partnerid
  
    }
    //console.log(JSON.stringify(b));
    this.dataService.addBusiness2(JSON.stringify(b))
      .subscribe((res)=>{
        this._business=res;
        //console.log(JSON.stringify(res));
        this.businessid=this._business.id;
        //console.log("businessid="+JSON.stringify(this.businessid));
        console.log('Biz added');
        this.submitServices();
          this.submitOrder();
        //}
    },
    errors =>  
    {
      this.valaModal();
      this.error = errors.error.errors;
    }
  );
  }

  submitServices(){
    if(this.services.length>0){
      //this._submitting="SUBMITTING SERVICES IMPROVEMENT INFO"
    console.log('Submitting Services');
    console.log('S='+JSON.stringify(this.services));
    this.dataService.addServices(this.businessid, this.services)
      .subscribe((res)=>{
        this._services=res;
        console.log(JSON.stringify('S:'+JSON.stringify(res)));
        this.valaModal();
    },
    errors =>  
    {
      this.valaModal();
      this.error = errors.error.errors;
      //window.scrollTo(0,0);
    }
  );
    }else{
      this.valaModal();
    }
  }

  submitOrder(){
    this._submitting="PLACING YOUR ORDER";
    let message = this.lastForm.controls.message.value;
    console.log('Placing order');
    var o = {"street":this.order.street, "suburb":this.order.suburb, "town":this.order.town, "code":this.order.code, "province":this.order.province, "message":this.lastForm.controls.message.value}
    //console.log(JSON.stringify('ORDER'+JSON.stringify(o)));
    this.dataService.addOrder(this.businessid, o)
      .subscribe((res)=>{
        this._order=res;
        console.log('Order added');
        //console.log(JSON.stringify('ORDER='+JSON.stringify(res)));
        this.valaModal();
    },
    errors =>  
    {
      this.valaModal();
      this.error = errors.error.errors;
      //window.scrollTo(0,0);
    }
  );
    
  }

  getAvatar(event) {
    this._logo=false;
    this._avatar=true;
    this._image1=false;
    this._image2=false;
    this._image3=false;
    this._image4=false;
    var files = event.target.files;
      var file = files[0];

    if (files && file) {
        var reader = new FileReader();

        reader.onload =this.handleFile.bind(this);

        reader.readAsBinaryString(file);
    }
}

getLogo(event) {
  this._logo=true;
  this._avatar=false;
  this._image1=false;
  this._image2=false;
  this._image3=false;
  this._image4=false;
  var files = event.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
  }
}

getImage1(event) {
  this._logo=false;
  this._avatar=false;
  this._image1=true;
  this._image2=false;
  this._image3=false;
  this._image4=false;
  var files = event.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
  }
}

getImage2(event) {
  this._logo=false;
  this._avatar=false;
  this._image1=false;
  this._image2=true;
  this._image3=false;
  this._image4=false;
  var files = event.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
  }
}

getImage3(event) {
  this._logo=false;
  this._avatar=false;
  this._image1=false;
  this._image2=false;
  this._image3=true;
  this._image4=false;
  var files = event.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
  }
}

getImage4(event) {
  this._logo=false;
  this._avatar=false;
  this._image1=false;
  this._image2=false;
  this._image3=false;
  this._image4=true;
  var files = event.target.files;
    var file = files[0];

  if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
  }
}

handleFile(event) {
  var binaryString = event.target.result;
  var b4= btoa(binaryString);
  if(this._logo){
    this.logob64=b4;
  }
  if(this._avatar){
    this.avatarb64=b4;
  }
  if(this._image1){
    this.image1b64=b4;
  }
  if(this._image2){
    this.image2b64=b4;
  }
  if(this._image3){
    this.image3b64=b4;
  }
  if(this._image4){
    this.image4b64=b4;
  }
  //console.log(btoa(binaryString));
 }

 fbin(){
  this.fb="www.facebook.com/";
}

fbout(){
  if(!this.lastForm.controls.fbpage.value){
    this.fb="";
  }
 
}

twitterin(){
  this.twitter="www.twitter.com/";
}

twitterout(){
  if(!this.lastForm.controls.twitterpage.value){
    this.twitter="";
  }
 
}

instain(){
  this.fb="www.instagram.com/";
}

instaout(){
  if(!this.lastForm.controls.instapage.value){
    this.insta="";
  }
 
}
  

  openModal(f) {
    this.modalService.open(f, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered:true, keyboard: false, windowClass: 'my-class'
    }).result.then((result) => {
    }, (reason) => {
    });
  }
  
  valaModal(){
    this.modalService.dismissAll();
  }

  filterArray(i, arrayData){
    //console.log('arrayData='+ (JSON.stringify(arrayData)));
    //console.log('i='+ (i));
    var filtered = arrayData.filter(
      //arrayData => (arrayData.categoryid.toString().toLowerCase()) === i.toString().toLowerCase());
      arrayData => (arrayData.name).toString().toLowerCase() !== i.toString().toLowerCase());
      //console.log('filtered='+ JSON.stringify(filtered));
      return filtered;
  }

}

interface P {
  value: string;
  viewValue: string;
}

