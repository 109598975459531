<div class="container" style="margin-top: 100px;">
    <div class="row col-lg-12">
        <span style="font-size: 1.8rem; line-height: 1.8rem; color: #a0a0a0;text-align: center; margin-bottom:10px ;">Create you own digital card and profile</span>
    </div>
    <div class="row col-lg-12 row">
        <div class="col col-lg-6" style="background-color: #cccccc; min-height: 1000px;">
        </div>
        <div class="col col-lg-6" >
            <div style="margin:10px; border-radius: 10px; min-height: 600px; width: 400px; background-color: #ffffff; box-shadow: 5px 10px 10px 5px #888888;">

                <div class="mycontainer row col-xs-12 col-sm-12 col-md-12 col-lg-12" >
                    <div class="header row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: 0px;  margin: 0px; background: none;">
                       <div class="row header-content col-xs-12 col-sm-12 col-md-12 col-lg-12" style="background-color: #4a0202;">
                          <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                             <div style="margin-top: 0rem;">
                                 <img style="border-radius: 50%; height: 250px; width: 250px; border: 0px solid #fff;" class="img-fluid" src="https://api.profila.co.za/images/" alt="alternative" />
                             </div> 
                             <div style="margin-top: 0rem;">
                                 <img style="border-radius: 50%; height: 250px; width: 250px; border: 0px solid #fff;" class="img-fluid" src="https://api.profila.co.za/images/img_placeholder_avatar.jpg" alt="alternative" />
                             </div>
                         </div>
                         <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: -12rem; margin-left: 12rem;">
                             <div style="margin-top: 0rem;">
                                 <img style="border-radius: 50%; height: 100px; width: 100px; border: 0px solid #fff;" class="img-fluid" src="https://api.profila.co.za/images/" alt="alternative" />
                             </div> 
                             <div style="margin-top: 0rem;">
                                 <img style="border-radius: 50%; height: 100px; width: 100px; border: 0px solid #fff;" class="img-fluid" src="https://api.profila.co.za/images/img_placeholder_avatar.jpg" alt="alternative" />
                             </div>
                         </div>
                         <div class="row col-lg-12">
                             <div style="padding: 0.4rem;">
                                <span class="capitalise" style="font-size: 1.4rem; line-height: 1.4rem; color: #fff; display: block; margin-bottom: 0.4rem; font-weight: 400;">{{contactname}} {{surname}}</span>
                                <span class="capitalise" style="font-size: 1.1rem; line-height: 1.1rem; color: #d0d0d0;display: block;margin-bottom: 0.4rem; font-style: italic; font-weight: 300px;">{{contactjobtitle}}</span>
                                <span class="capitalise" style="font-size: 1.2rem; line-height: 1.2rem; color: #fff;display: block; font-weight:500;">{{name}}</span>
                             </div>
                          </div>  
                          <div class="row">
                             <div class="text-container" style="padding: 1rem; padding-top: 0.2rem;">
                                 <span style="font-size: 0.9rem; line-height: 0.9rem; color: #f7f7f7; display: block; text-align: center; font-weight: 300;">{{contactbrief}}</span>
                             </div>
                         </div>
                         
                          <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="height: 3rem;"></div>
                       </div>
                 
                          <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding:0px; margin:0px; min-height: 55px;">
                                   <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4 small-card">
                                      <div class="small-card-content" style="text-align: center;">
                                         <span class="card-text">CALL</span>
                                         <a href="tel:"><fa-icon [icon]="faPhoneSquare" class="card-image"></fa-icon></a>
                                      </div>
                                   </div>
                                   <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4 small-card">
                                      <div class="small-card-content" style="text-align: center;">
                                         <span class="card-text">E-MAIL</span>
                                         <a href="mailto:"><fa-icon [icon]="faPaperPlane" class="card-image"></fa-icon></a>
                                      </div>
                                   </div>
                                   <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4 small-card" onclick="prepareContact()">
                                      <div class="small-card-content" style="text-align: center;">
                                         <span class="card-text">SAVE CONTACT</span>
                                         <a><fa-icon [icon]="faCloudDownloadAlt" class="card-image"></fa-icon></a>
                                      </div>
                                   </div>
                          </div>
                    </div>
                 
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 20px; padding-left: 20px;padding-right: 5px;">
                       
                       <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <span style="font-size: 1.6rem; line-height: 1.6rem; color: #7b7b7b;display: block; font-weight:300; text-align:center">{{businessbrief}}</span>
                       </div>
                    </div>
                 
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 20px; padding-left: 20px;padding-right: 5px;">
                       <ul id="myUl"></ul>
                       <div class="lowercase" id="myServices"></div>
                    </div>
                 
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 2rem;">
                    
                       <div class="image-container">
                           <img class="myimages" src="https://api.profila.co.za/images/" alt="alternative" />
                       </div> 
                       <div class="image-container">
                           <img class="myimages" src="https://api.profila.co.za/images/" alt="alternative" />
                       </div> 
                       <div class="image-container">
                           <img class="myimages" src="https://api.profila.co.za/images/" alt="alternative" />
                       </div> 
                       <div class="image-container">
                           <img class="myimages" src="https://api.profila.co.za/images/" alt="alternative" />
                       </div>
                 
                   </div>
                 
                   <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 2rem;padding-left: 20px;padding-right: 5px;">
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-bottom: 10px;">
                         <span style="font-size: 2.0rem; line-height: 2.0rem; color: #363636;display: block; font-weight:400;"> <fa-icon [icon]="faComments" class="menu-icon"></fa-icon>&nbsp;Get in touch</span>
                     </div>
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 contact-section" >
                         <span style="font-size: 1.6rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">Mobile</span>
                         <span style="font-size: 1.8rem; color: #a0a0a0;display:flex;"><a href="tel:{{contactnumber}}">{{contactnumber}}</a></span>
                     </div>
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 contact-section">
                         <span style="font-size: 1.6rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">Work</span>
                         <span style="font-size: 1.8rem; color: #a0a0a0;display:flex;"><a href="tel:{{tel}}">{{tel}}</a></span>
                     </div>
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 contact-section">
                         <span style="font-size: 1.6rem; line-height: 1.2rem; color: #a0a0a0;display:flex; font-weight: 300; font-style: italic;">E-mail</span>
                         <span style="font-size: 1.8rem; color: #a0a0a0;display:flex;"><a href="mailto:{{contactemail}}">{{contactemail}}</a></span>
                     </div>
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 contact-section">
                         <span style="font-size: 1.6rem; line-height: 1.2rem; color: #a0a0a0;display:flex;font-weight: 300; font-style: italic;">Website</span>
                         <span style="font-size: 1.8rem; color: #a0a0a0;display:flex;"><a href="{{website}}">{{website}}</a></span>
                     </div>
                     <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style=" margin-bottom: 0.5rem; margin-top: 1.0rem;">
                         <span style="font-size: 1.6rem; line-height: 1.8rem; color: #636363;display:flex; font-weight: 400;">Address</span>
                         <span class="capitalise address-text">{{rstreet | titlecase}}</span>
                         <span class="capitalise address-text">{{rsuburb | titlecase}}</span>
                         <span class="address-text">{{rtown | titlecase}}</span>
                         <span class="capitalise address-text">{{rprovince | titlecase}}</span>
                     </div>
                     
                 </div> 
                 <div  class="row col-xs-12 col-sm-12 col-md-12 col-lg-12" style="margin-top: 2rem;">
                    
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 social-block" style="background-color: #25D366;">
                        <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
                            <a href="https://wa.me/"><fa-icon [icon]="faWhatsapp" class="social-icon"></fa-icon></a>
                        </div>
                        <div class="col col-xs-8 col-sm-8 col-md-8 col-lg-8" style="height: 70px; padding-top: 5px;">
                            <a href="https://wa.me/"><span style="font-size: 2.2rem; color: #fff;">WhatsApp</span><br>
                            <span style="font-size: 1.2rem; color: #fff;">Chat to us</span></a>
                        </div>
                       
                    </div>
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 social-block" style="background-color: #3b5998;">
                        <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
                            <a href="https://facebook.com/"><fa-icon [icon]="faFacebook" class="social-icon"></fa-icon></a>
                        </div>
                        <div class="col col-xs-8 col-sm-8 col-md-8 col-lg-8" style="height: 70px; padding-top: 5px;">
                            <a href="https://facebook.com/"><span style="font-size: 2.2rem; color: #fff;">Facebook</span><br>
                            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
                        </div>
                       
                    </div>
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 social-block" style="background-color: #00acee;">
                        <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
                            <a href="https://twitter.com/"><fa-icon [icon]="faTwitter" class="social-icon"></fa-icon></a>
                        </div>
                        <div class="col col-xs-8 col-sm-8 col-md-8 col-lg-8" style="height: 70px; padding-top: 5px;">
                            <a href="https://twitter.com/"><span style="font-size: 2.2rem; color: #fff;">Twitter</span><br>
                            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
                        </div>
                       
                    </div>
                    <div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 social-block" style="background-color: #d62976;">
                        <div class="col col-xs-4 col-sm-4 col-md-4 col-lg-4" style="height: 70px; padding: 2px; text-align: right; padding-right: 3rem;">
                            <a href="https://instagram.com/"><fa-icon [icon]="faInstagram" class="social-icon"></fa-icon></a>
                        </div>
                        <div class="col col-xs-8 col-sm-8 col-md-8 col-lg-8" style="height: 70px; padding-top: 5px;">
                            <a href="https://instagram.com/"><span style="font-size: 2.2rem; color: #fff;">Instagram</span><br>
                            <span style="font-size: 1.2rem; color: #fff;">Follow us</span></a>
                        </div>
                       
                    </div>
                   
                 </div>
                 
                 </div>


            </div>
        </div>

    </div>
            
</div>
