import { Component, OnInit } from '@angular/core';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-createcard',
  templateUrl: './createcard.component.html',
  styleUrls: ['./createcard.component.css']
})
export class CreatecardComponent implements OnInit {
  faComments = faComments;
  faWhatsapp = faWhatsapp;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faPaperPlane = faPaperPlane;
  faPhoneSquare = faPhoneSquare;
  businessbrief:string;
  contactbrief:string;
  contactnumber:string;
  tel:string;
  contactemail:string;
  website:string;
  rstreet:string;
  rsuburb:string;
  rtown:string;
  rprovince:string;
  contactname:string;
  surname:string;
  contactjobtitle:string;
  name:string


  constructor() { }

  ngOnInit(): void {
    this.placeholder();
  }

  placeholder(){
    this.businessbrief='A brief description of what your business does.';
    this.contactbrief='A brief info about what you do';
    this.contactnumber='0780949997';
    this.tel='0318934552';
    this.contactemail='jane@company.com';
    this.website="www.company.com";
    this.rstreet='5, SINCLAIR BUILDING';
    this.rsuburb='WOOLEY STREET';
    this.rtown='Port Shepstone';
    this.rprovince='KwaZulu Natal';
    this.contactname='Jane';
    this.surname='Sishi';
    this.contactjobtitle='Designer';;
    this.name="The creative co.";
  }

}
