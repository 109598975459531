import { Component, OnInit } from '@angular/core';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faPhoneSquare} from '@fortawesome/free-solid-svg-icons';
import { faMobile} from '@fortawesome/free-solid-svg-icons';
import { faCloud} from '@fortawesome/free-solid-svg-icons';
import { faCloudDownloadAlt} from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { faBell} from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { DataService } from '../../services/data.service';
import { ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  faPhone = faPhone;
  faPhoneSquare = faPhoneSquare;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;
  faMobile = faMobile;
  faCloud = faCloud;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faInfoCircle = faInfoCircle;
  faWork = faBell;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faInstagram = faInstagram;
  business:any;
  id:any;
  photo:any;
  address:string="http://api.profila.co.za/images/";
  i:any;
 
  name = 'Sane';
    surname = 'Jula';
    org = 'AA';
    url = 'www.aa.com';
    email = 'john@aa.com';
    tel = '031 111 222';
    vCardInfo:string;

    hbgcolor:string;
  
  constructor(
    private dataService:DataService,
    private activatedRoute : ActivatedRoute,
    private titleService:Title,
  ) { }

  ngOnInit(): void {
    this.hbgcolor="#a52a2a"
    this.id = this.activatedRoute.snapshot.paramMap.get("id");
    this.getBusiness2();
    this.dataService.setNav(false);
  }

  getBusiness(){
    this.dataService.getBusinessbyid(this.id).subscribe((res)=>{
      this.business=res;
      this.business=this.business[0];
      if(this.business.hbgcolor!=null){
        this.hbgcolor=this.business.hbgcolor;
      }
      //console.log('B='+JSON.stringify(this.business));
      
      },
    errors =>  
      {
       
      }
      );
  }

  getBusiness2(){
    this.dataService.getBusinessbyaccountid(this.id).subscribe((res)=>{
      this.business=res;
      this.business=this.business[0];
      console.log('B='+JSON.stringify(this.business));
      if(this.business.name!=null){
        this.titleService.setTitle(this.business.name +' - '+this.business.businessbrief);
      }else{
        this.titleService.setTitle(this.business.contactname+' '+this.business.surname+' - '+this.business.contactbrief);
      }
      if(this.business.hbgcolor!=null){
        this.hbgcolor=this.business.hbgcolor;
      }
      
      if(!this.business){
        this.getBusiness();
      }
      },
    errors =>  
      {
       
      }
      );
  }

  prepareContact(){
    var f=this.business.avatar;
    if(f){
    console.log('F='+f)
    this.dataService.getImage(f).subscribe((res)=>{
      this.i=res;
      this.photo=this.i.image;
      //this.business=this.business[0];
      //console.log('I='+JSON.stringify(this.i.image));
      this.saveContact();
      },
    errors =>  
      {
       
      }
      );
    }else{
      this.saveContact();
    }
  }


  saveContact(){
    console.log('N:'+this.surname)
    this.vCardInfo = `BEGIN:VCARD
VERSION:3.0
N:${this.business.surname};${this.business.contactname}
FN:${this.business.surname} ${this.business.contactname}
ORG:${this.business.name}
URL:${this.business.website}
EMAIL:${this.business.contactemail}
TEL;TYPE=voice,work,pref:${this.business.contactnumber}
TEL;TYPE=voice,cell:${this.business.contactnumber}
TITLE:${this.business.contactjobtitle}
NOTE:${this.business.contactbrief}
PHOTO;ENCODING=b;TYPE=JPEG:${this.photo}
END:VCARD
`

    let fileName=this.business.contactname+".vcf";
    let fileContent=this.vCardInfo;
    const file=new Blob([fileContent],{type:"text/plain"});
    const link=document.createElement("a");
    link.href=URL.createObjectURL(file);
    link.download=fileName;
    link.click();
    link.remove();
  }

 


}
