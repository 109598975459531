<div class="container" style="margin-top: 100px;">
    <div class="row col-lg-12">
        <div class="row col-lg-4 row">
            <span style="font-size: 1.4rem; line-height: 1.6rem; color: #a0a0a0;display:flex;">Capture new profile</span>
        </div>
        <div class="row col-lg-4 row">
            <span style="font-size: 0.8rem; color: #737373;display:flex;">Account id : {{accountid | lowercase}}</span>
        </div>
        <div class="form-group col-lg-3 row">
            <label class="control-label col-lg-3" style="font-size: 0.8rem; color: #737373;display:flex;" for="theme">Theme</label>
              
            <div class="form-group col-lg-9">
              <input type="text" maxlength="50" class="form-control col-lg-9" name="theme" id="theme" [(ngModel)]="theme" />          
            </div>
        </div>
    </div>
 <mat-horizontal-stepper labelPosition="bottom" linear #stepper>

  <mat-step [stepControl]="personalForm" label="Personal">
    <form [formGroup]="personalForm">
        <div class="row col-lg-12">
         
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input (focusout)="doAccountid()" matInput placeholder="First name" formControlName="contactname" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Surname</mat-label>
                <input (focusout)="doAccountid()" matInput placeholder="Last name" formControlName="surname" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Give a brief info about what you do (optional)</mat-label>
                <input matInput placeholder="e.g I design modern graphics with a strong focus on minimalism." formControlName="contactbrief">
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>E-mail Address</mat-label>
                <input matInput placeholder="E-mail address" formControlName="email" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Contact Number</mat-label>
                <input matInput placeholder="Contact number" formControlName="tel" required>
            </mat-form-field>
        </div>
        
      <div>
        <button mat-button matStepperNext (click)="next()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="businessForm" label="Business">
    <form [formGroup]="businessForm">
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Business Name (optional)</mat-label>
                <input matInput formControlName="name" placeholder="Business name">
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>What does the business do? (optional)</mat-label>
                <input matInput placeholder="e.g We offer branding solutions to local and international businesses." formControlName="businessbrief">
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Job Title (optional)</mat-label>
                <input matInput placeholder="e.g Graphic Designer" formControlName="contactjobtitle">
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Website Address (optional)</mat-label>
                <input matInput formControlName="website" placeholder="Website address">
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>What product / services do you offer? (optional)</mat-label>
                <input matInput formControlName="services" placeholder="Products / services">
            </mat-form-field>
        </div>
        <div>
            <div>
            <span class="col" style="font-size: 0.6rem; line-height: 0.6rem; color: #a0a0a0;display:flex;">WEB DESIGN</span>
            <span class="col" style="font-size: 0.6rem; line-height: 0.6rem; color: #a0a0a0;display:flex;">WEB DESIGN</span>
            </div>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>What product / services do you offer? (optional)</mat-label>
                <input matInput formControlName="services" placeholder="Products / services">
            </mat-form-field>
            <button style="text-align: right;" mat-button (click)="addService()">Add</button>
        </div>
        <div>
            
            <div>
                <span *ngFor="let s of services" class="badge text-bg-warning" style="font-size: 14px; line-height: 14px; font-weight: 300; color: #ffffff;display:flex; background-color: #f05400; display: inline-block; width: auto; margin: 2px; padding: auto;">{{s.name}}  &nbsp;<fa-icon (click)="remove(s.name)" [icon]="faMinusCircle" size="2x" [styles]="{'color': '#fff'}"></fa-icon></span>
            </div>
        </div>
        <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext (click)="next()">Next</button>
        </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="addressForm" label="Address">
    <form [formGroup]="addressForm">
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Street Number</mat-label>
                <input matInput formControlName="rstreet" placeholder="e.g. 3 Ridge street" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Suburb / Building</mat-label>
                <input matInput formControlName="rsuburb" placeholder="Oslo Beach" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Province</mat-label>
                <!-- <input matInput formControlName="rprov" placeholder="Kwazulu-Natal" required> -->
                <mat-select name="rprov" formControlName="rprov">
                    <mat-option *ngFor="let prov of provinces" [value]="prov.viewValue">
                      {{prov.viewValue}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>City / Town</mat-label>
                <input matInput formControlName="rtown" placeholder="Durban" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Code</mat-label>
                <input matInput formControlName="rcode" placeholder="4240" required>
            </mat-form-field>
        </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="addressnext()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="shippingForm" label="Shipping">
    <form [formGroup]="shippingForm">
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Street Number</mat-label>
                <input matInput formControlName="sstreet" placeholder="e.g. 3 Ridge street" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Suburb / Building</mat-label>
                <input matInput formControlName="ssuburb" placeholder="Oslo Beach" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Province</mat-label>
                <input matInput formControlName="sprov" placeholder="Kwazulu-Natal" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>City / Town</mat-label>
                <input matInput formControlName="stown" placeholder="Durban" required>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Code</mat-label>
                <input matInput formControlName="scode" placeholder="4240" required>
            </mat-form-field>
        </div>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="next()">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step  label="Uploads">
    <div class="row col-lg-12">
        <div class="col col-lg-6">
            <h2>Select a profile picture (optional)</h2>
            <input class="btn btn-info" type="file" id="avatar" placeholder="Upload profile picture" (change)="getAvatar($event)"> 
        </div>
        <div class="col col-lg-6">
            <h2>Select a logo (optional)</h2>
            <input class="btn btn-info" type="file" id="logo" placeholder="Upload logo" (change)="getLogo($event)"> 
        </div>
    </div>
    <hr />
    <div class="row col-lg-12">
        <h2>Add images to showcase your work (optional)</h2>
    <div class="row col-lg-12" style="margin-bottom: 10px;">
        <div class="col col-lg-6">
            <input class="btn btn-warning" type="file" id="image1" placeholder="Upload image" (change)="getImage1($event)"> 
        </div>
        <div class="col col-lg-6">
            <input class="btn btn-warning" type="file" id="image2" placeholder="Upload image" (change)="getImage2($event)"> 
        </div>
    </div>
    <div class="row col-lg-12">
        <div class="col col-lg-6">
            <input class="btn btn-warning" type="file" id="image3" placeholder="Upload image" (change)="getImage3($event)"> 
        </div>
        <div class="col col-lg-6">
            <input class="btn btn-warning" type="file" id="image4" placeholder="Upload image" (change)="getImage4($event)"> 
        </div>
    </div>
    </div>
    
    <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="next()">Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="lastForm" label="Finish">
    <form [formGroup]="lastForm">
        
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Facebook (optional)</mat-label>
                <div class="row col-lg-12">
                    <span style="display: table">
                        <label >{{fb}}</label>
                    </span>
                    <div class="col">
                        <input (focusin)="fbin()" (focusout)="fbout()" matInput formControlName="fbpage" placeholder="facebook page" maxlength="100">
                    </div>
                </div>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Twitter (optional)</mat-label>
                <div class="row col-lg-12">
                    <span style="display: table">
                        <label >{{twitter}}</label>
                    </span>
                    <div class="col">
                        <input (focusin)="twitterin()" (focusout)="twitterout()" matInput formControlName="twitterpage" placeholder="Twitter page" maxlength="100">
                    </div>
                </div>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Instagram (optional)</mat-label>
                <div class="row col-lg-12">
                    <span style="display: table">
                        <label >{{insta}}</label>
                    </span>
                    <div class="col">
                        <input (focusin)="instain()" (focusout)="instaout()" matInput formControlName="instapage" placeholder="Instagram page" maxlength="100">
                    </div>
                </div>
            </mat-form-field>
        </div>
        <div class="row col-lg-12">
            <mat-form-field appearance="fill">
                <mat-label>Message</mat-label>
                <input matInput formControlName="message" placeholder="What else would you want us to know?">
            </mat-form-field>
        </div>
        <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="submit()">Create Card</button>
        </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
</div>

<ng-template #submitting let-modal>
    
    <div class="modal-body center">
        <fa-icon [icon]="faSpinner" spin="true" size="3x" [styles]="{'color': '#839192'}"></fa-icon>
        <div class="spacer20"></div>
        <p class="processing">Submitting...</p>
        <p class="progressing">{{_submitting}}</p>
    </div>
    <!--<div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
      </div>-->
   
  </ng-template>
